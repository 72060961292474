import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, Flex } from 'rebass';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Slide from 'react-reveal/Slide';
import Section from '../components/Section';
import { CardContainer, Card } from '../components/Card';
import Triangle from '../components/Triangle';
import ImageSubtitle from '../components/ImageSubtitle';

const Background = () => (
  <div>
    <Triangle
      color="backgroundDark"
      height={['30vh', '30vh']}
      width={['100vw', '100vw']}
      invertX
    />

    <Triangle
      color="secondaryLight"
      height={['25vh', '40vh']}
      width={['75vw', '60vw']}
      invertY
    />

    <Triangle
      color="primaryLight"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
      invertY
      invertX
    />
  </div>
);

const CARD_HEIGHT = '400px';
const SMALL_CARD_HEIGHT = '380px';

const MEDIA_QUERY_SMALL = '@media (max-width: 400px)';

const Title = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: table;
  border-bottom: ${props => props.theme.colors.primary} 5px solid;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  width: 100%;
`;

const ImageContainer = styled.div`
  margin: auto;
  width: 100%;
  text-align: center;

  ${MEDIA_QUERY_SMALL} {
    margin: 20px auto;
  }
`;

const CareerImage = styled(Image)`
  margin: 15px 40px;
  margin-top: 0px;
  border-radius: 10px;

  ${MEDIA_QUERY_SMALL} {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const InnerCard = styled(Flex)`
  height: ${CARD_HEIGHT};
  ${MEDIA_QUERY_SMALL} {
    height: ${SMALL_CARD_HEIGHT};
  }
`;

const Career = ({
  name,
  jobtitle,
  logo,
  start = '',
  end = '',
}) => (
  <Card p={0}>
    <InnerCard flexDirection="column">
      <TextContainer>
        <span>
          <Title my={2} pb={1}>
            {jobtitle}
          </Title>
        </span>
      </TextContainer>
      <ImageContainer>
        <CareerImage src={logo.image.src} alt={logo.title} />
        <Text width={[1]} style={{ overflow: 'auto', marginTop: "auto" }}>
          {name}
        </Text>
      </ImageContainer>
      {start && start != '' && (
        <ImageSubtitle
          bg="primaryLight"
          color="white"
          y="bottom"
          x="right"
          round
        >
          {`${start  } - ${  end || 'NU'}`}
        </ImageSubtitle>
      )}
    </InnerCard>
  </Card>
);

Career.propTypes = {
  name: PropTypes.string.isRequired,
  jobtitle: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
};

const Careers = () => (
  <Section.Container id="career" Background={Background}>
    <Section.Header name="Career" icon="💼" Box="briefcase" />
    <StaticQuery
      query={graphql`
        query CareersQuery {
          allContentfulCareer(sort: { fields: start, order: ASC }) {
            edges {
              node {
                id
                name
                jobtitle
                start(formatString: "YYYY")
                end(formatString: "YYYY")
                logo {
                  title
                  image: resize(width: 200, height: 200, quality: 100, resizingBehavior: PAD) {
                    src
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allContentfulCareer }) => (
        <CardContainer minWidth="280px" justify="stretch">
          {allContentfulCareer.edges.map((p, i) => (
            <Slide bottom delay={i * 200}>
              <Career key={p.node.id} {...p.node} />
            </Slide>
          ))}
        </CardContainer>
      )}
    />
  </Section.Container>
);

export default Careers;
