module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#726877',

  primary: '#171B40',
  primaryLight: '#2D367F',
  primaryDark: '#0D1026',

  secondary: '#B8CDE2',
  secondaryLight: '#C0D6ED',
  secondaryDark: '#717E8C',
};